.document {
    display: flex;
    flex-wrap: wrap;
    &__icon {
        &--folder {
            i {
                color: $yellow;
                font-size: 4rem;
            }
        }
        &--doc {
            i {
                color: $blue;
                font-size: 4rem;
            }
        }
    }

    &__pagination {
        margin-top: 4rem;
        font-size: 1rem;
        display: flex;
        align-items: center;

        i {
            color: $yellow;
            font-size: 2rem;
            margin-right: 1rem;
        }

        a {
            margin: 0 1rem;
            color: $blue;
            text-decoration: underline;
            font-size: 1.5rem;
            cursor: pointer;
        }
    }

    &__left {
        width: calc(70% - 2rem);
        margin-right: 2rem;
        margin-bottom: 2rem;

        &--2 {
            width: calc(66.66% - 2rem);
        }

        &__title {
            font-weight: bold;
            padding: 0;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__content {
            padding: 1rem 0 0 0;
        }
    }

    &__right {
        width: calc(30% - 2rem);
        margin-right: 2rem;
        margin-bottom: 2rem;
        background-color: #fff;
        border: 1px solid $border;

        &--2 {
            width: calc(66.66% - 2rem);
        }

        &__title {
            font-weight: bold;
            padding: 0;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__content {
            padding: 1rem 0 0 0;
        }
    }
}
