input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='tel'],
input[type='datetime-local'],
input[type='time'],
textarea,
select {
    font-family: 'Inter', sans-serif;
    font-size: 1.7rem;
    background-color: #fff;
    width: 100%;
    border-radius: 3px;
    border: 1px solid $border;
    padding: 1.5rem 1.5rem;
    line-height: 2rem;
    height: 5rem;

    &:focus,
    &:active {
        border: 1px solid $blue;
        outline: none;
    }

    &[disabled] {
        cursor: not-allowed;
        background: #fcfcfc;
        color: #999;
    }

    &.form-code {
        font-family: 'Courier New', Courier, monospace;
    }
}

.ng2-tag-input {
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    width: 100%;
    border-radius: 3px;
    border: 1px solid $border !important;
    padding: 0 !important;

    .ng2-tags-container {
        padding: 0;

        input {
            padding: 0 1.5rem;

            &:focus,
            &:active {
                border: 0;
                outline: none;
            }
        }

        tag {
            height: 3.5rem;
            margin: 0.5rem 0 0 0.5rem;
            line-height: 3.5rem;
            padding: 0 0 0 1.5rem;
            font-size: 1.5rem;
            border-radius: 3px;
            background-color: $tagcolor;

            &:hover {
                box-shadow: none !important;
                background-color: $tagcolor !important;
            }

            delete-icon {
                width: 3.5rem;
                height: 3.5rem;
                text-align: center;
                line-height: 3.5rem;
                margin-left: 0.5rem;

                &:hover {
                    transform: none !important;
                }

                svg {
                    height: 3.5rem;
                }
            }
        }
    }
}

textarea {
    min-height: 11rem;
    line-height: 2.5rem;
}

select {
    padding: 0 2rem;
    line-height: 5rem;
}

.toggle {
    width: 4rem;
    height: 2.4rem;
    background-color: $border;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 2rem;
    cursor: pointer;
    justify-content: flex-start;
    animation: all 200ms ease-in;
    padding: 0.25rem;

    &:after {
        content: '';
        width: 1.9rem;
        height: 1.9rem;
        border-radius: 2rem;
        background-color: #fff;
    }

    &--active {
        background-color: $green;
        justify-content: flex-end;
    }

    &--disabled {
        cursor: not-allowed;
        opacity: 0.25;
    }
}

form {
    h3 {
        text-transform: uppercase;
        font-size: 1.25rem;
        margin: 6rem 0 4rem 0;
    }
}

.strength-meter {
    height: 2px !important;
    background-color: $border !important;
}

.form-group {
    label {
        font-size: 1.5rem;
        display: flex;
        align-items: center;
    }

    &.required {
        label {
            &:after {
                margin-left: 0.5rem;
                content: ' ';
                background-color: $red;
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 1rem;
            }
        }
    }

    &--toggle {
        label {
            display: flex;
            align-items: center;
            padding: 0.75rem 0;
            cursor: pointer;

            input,
            .toggle {
                margin-right: 1rem;
            }
        }

        &--big {
            label {
                font-size: 1.75rem;
            }
        }
    }

    &--colors {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-gap: 1rem;
        justify-items: center;
        align-items: center;

        li {
            width: 3.4rem;
            height: 3.4rem;
            border-radius: 3rem;
            cursor: pointer;
            margin: 0;

            &.selected {
                border: 2px solid #fff;
                box-shadow: 0 0 0 1px $grey;
            }
        }
    }

    &--image {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        padding-left: 1rem;
        border: 1px solid $border;
        border-radius: 4px;

        &__preview {
            min-width: 5rem;
            max-width: 5rem;
            min-height: 5rem;
            max-width: 5rem;
            margin-right: 2rem;
            background-size: cover;
            background-position: center;
        }

        &__input {
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            input {
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
            }
        }

        &__filename {
            width: 100%;

            &--empty {
                opacity: 0.5;
            }
        }

        &__delete {
            font-size: 3rem;
            width: 3rem;
            height: 3rem;
            line-height: 3rem;
            text-align: center;
            cursor: pointer;
        }
    }
}

.form-logo {
    position: relative;
    max-width: 8rem;
    min-width: 8rem;
    min-height: 8rem;
    max-height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
        font-size: 4rem;
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        z-index: 1;
        cursor: pointer;
        display: none;
        border-radius: 0.5rem;
    }

    &:hover {
        i {
            display: flex;
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 0.5rem;
    }

    input {
        position: absolute;
        background: red;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
    }
}

.form-helptext {
    font-size: 1.25rem;
    display: block;
    padding: 0.5rem 0 1rem 0;
    color: #777;

    &--no-padding {
        padding: 0;
    }
}

.form-error {
    color: $red;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
    display: block;
    padding: 0.5rem 0 1rem 0;
}

.choices {
    display: flex;
    width: 100%;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        flex-direction: column;
    }
}

.choice {
    &__item {
        border-radius: 3px;
        flex: 1;
        margin: 0 1rem;
        padding: 4rem;
        color: #fff;
        background-color: $blue;
        display: flex;
        align-items: center;
        margin-bottom: 4rem;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0 0 2rem 0;
        }

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        h2 {
            margin-bottom: 0.5rem;
        }

        &__icon {
            min-width: 4rem;
            max-width: 4rem;
            text-align: center;
            font-size: 4rem;
            margin-right: 4rem;
        }

        &--alt {
            background-color: $purple;
        }
    }
}

.input {
    &--pagination {
        width: 80px;
        text-align: center;
    }

    &--bg-transparant {
        background-color: transparent !important;
    }

    &--currency {
        position: relative;

        input {
            padding-left: 30px;
        }

        &:before {
            content: '€';
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
