.badge {
    padding: 0.25rem 0.5rem;
    background-color: $border;
    color: #777;
    font-size: 1rem;
    font-weight: bold;
    vertical-align: middle;
    border-radius: 3px;
    text-decoration: none;
    text-transform: uppercase;

    &--operator {
        width: auto;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 0 0.75rem;
        text-align: center;
        margin: 0 0.75rem;
    }

    &--success {
        background-color: $green;
        color: #fff;
    }

    &--danger {
        background-color: $red;
        color: #fff;
    }

    &--warning {
        background-color: $orange;
        color: #fff;
    }

    &--yellow {
        background-color: $yellow;
        color: #fff;
    }

    &--info {
        background-color: $blue;
        color: #fff;
    }
}
