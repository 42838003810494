table.mat-table {
    width: 100%;
    min-width: 100%;
    font-size: 1.6rem;
    line-height: 2.4rem;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
        height: 4.5rem;

        &:hover {
            td {
                background-color: rgba(#fff, 0.96);
            }
        }
    }

    th,
    td {
        background-color: #fff;
        padding: 0.5rem 1.25rem !important;
        border: 1px solid $border;
        white-space: nowrap;
        vertical-align: middle;
        color: black;
        font-size: 1.5rem;
    }

    th {
        text-align: left;
        font-weight: 300;
        color: $grey;
        padding: 1rem 1.25rem;
    }

    .td-icon {
        padding: 0.75rem !important;
    }
}

.mat-paginator {
    background: none;
    color: #000;

    &-icon {
        width: 2.5rem !important;
    }

    &-range-label {
        margin: 0 0 0 2rem;
        opacity: 0.5;
    }

    .mat-icon-button {
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.mat-button-disabled.mat-button-disabled {
            color: #000;
            opacity: 0.25;
        }
    }
}

.td-small {
    width: 1rem;
}

.table {
    &__overflow {
        // TODO: overflow --> when settingsmodal opens overflow problem when 1 item in list
        //overflow: auto;
        width: 100%;
        padding-bottom: 1.5rem;
    }

    &__paginator {
        border: 1px solid #eee;
        background-color: #fff;

        &--top {
            border-bottom: 0;
        }

        &--bottom {
            border-top: 0;
        }
    }

    &__scroll {
        overflow: auto;
        width: 100%;
    }
}
.table-view {
    table-layout: auto;
    width: 100%;
    min-width: 100%;
    font-size: 1.6rem;
    line-height: 2.4rem;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
        height: 4.5rem;

        &:hover {
            td {
                background-color: rgba(#fff, 0.96);
            }
        }
    }

    th,
    td {
        background-color: #fff;
        padding: 0.5rem 1.25rem;
        border: 1px solid $border;
        white-space: nowrap;

        > i {
            font-size: 2rem;
            width: 2rem;
            text-align: center;
            margin-right: 0.5rem;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 3px;
        }

        input[type='checkbox'],
        input[type='radio'] {
            margin: 0;
            padding: 0;
            display: flex;
        }
    }

    th {
        text-align: left;
        font-weight: 300;
        color: $grey;
        padding: 1rem 1.25rem;
    }

    td {
        vertical-align: middle;

        &.pointer {
            text-decoration: underline;
        }
        &.pointer-relation {
            cursor: pointer;
            text-decoration: underline;
            text-decoration-style: dotted;
        }

        img {
            height: 2rem;
            vertical-align: middle;
            display: inline-block;
            border-radius: 3px;
        }
    }

    tr:hover {
        td {
            background-color: rgba($border, 0.1);
        }
    }

    &__add {
        height: 5rem;
        color: $blue;
        cursor: pointer;
        text-decoration: underline;
        font-size: 1.5rem;
    }

    .td-icon {
        padding: 0.75rem;
    }
}
