.modal {
    &-backdrop {
        background-color: rgba(0, 34, 80, 0.75);

        &--invisible {
            background-color: transparent;
        }
    }

    &-title {
        font-size: 2rem;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &-visual {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4rem 0 2rem 0;
        flex-direction: column;

        img {
            height: 10rem;
            width: auto;
        }
    }

    &-dialog {
        height: 100vh;
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        width: 50%;
        max-width: 80rem;

        @include media-breakpoint-down(sm) {
            width: 100%;
            max-width: 44rem;
        }
    }

    &-content {
        height: 100%;
        border: 0;
        border-radius: 0;
        margin: 0;
        padding: 0;
        box-shadow: 0px 0 20px rgba(0, 0, 0, 0.1);
        overflow-y: auto;

        > * {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &-huge {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-dialog {
            width: 65%;
            max-width: 65%;
        }
    }

    &-full-screen {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-dialog {
            width: 90%;
            max-width: 90%;
        }
    }

    &-notifications {
        width: 100vw;
        height: 100vh;

        .modal-dialog {
            border: 1px solid $border;
            left: 10rem;
            top: 1rem;
            width: fit-content;
            min-width: 500px;
            display: block;
            height: auto;
            max-width: 250px;
        }
    }

    &-prompt {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-image {
            width: 50%;
        }

        .modal-dialog {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            min-width: 100%;
            padding: 2rem;
        }

        .modal-content {
            height: auto;
            width: 50rem;
            border-radius: 3px;
        }

        &--min-content {
            .modal-content {
                width: min-content;
                min-width: 50rem;
            }
        }
    }

    .close {
        border: 0;
        font-size: 3rem;
        width: 5rem;
        height: 5rem;
        background: none;
        appearance: none;
        display: flex;
        outline: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border-radius: 2.5rem;
        background-color: $border;

        span {
            margin: 0;
            padding: 0;
            height: 5rem;
            width: 5rem;
            line-height: 5rem;
        }
    }

    &-header,
    &-footer {
        padding: 2rem 3rem;
        align-items: center;
    }

    &-footer {
        justify-content: space-between;
    }

    &-body {
        padding: 3rem;
        height: 100%;
        overflow-y: auto;

        /*.modal-dialog & {
            overflow: initial;
        }*/
    }
}

.two-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;

    .column-left {
        padding: 3rem;
        border-right: 1px solid #dee2e6;
    }

    .column-right {
    }
}
