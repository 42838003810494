@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.container-fluid {
    padding: 0 2rem;
}

.marketing {
    p {
        font-size: 2rem;
        opacity: 0.75;
    }

    header {
        background-color: #fff;
        padding: 3rem 0;
        border-bottom: 1px solid $border;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
        position: relative;

        app-logo {
            display: flex;

            img {
                margin: 0 2rem 0 0;
            }
        }

        nav {
            display: flex;
            justify-content: flex-end;

            a {
                margin: 0 1rem;
            }
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        font-size: 2rem;
        font-weight: 600;

        img {
            height: 4rem;
            margin-right: 2rem;
        }
    }

    &__hero {
        padding: 12rem 0;
        background-color: rgba(#fff, 0.5);
        border-bottom: 1px solid $border;

        h1 {
            font-family: 'Poppins', sans-serif;
            font-size: 5rem;
            line-height: 7rem;
            margin-bottom: 2rem;
        }

        p {
            font-size: 2.5rem;
            margin-bottom: 4rem;
        }
    }

    &__features {
        &__item {
            height: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid $border;
            padding: 4rem;

            &__icon {
                border: 1px solid $border;
                height: 6rem;
                width: 6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                margin-bottom: 2rem;
                background-color: $blue;
                border: 0;
                color: #fff;

                i {
                    font-size: 4rem;
                }
            }

            &__image {
                display: flex;
                margin-bottom: 2rem;

                img {
                    height: 6rem;
                }
            }

            &__title {
                margin-bottom: 1rem;
            }
        }
    }
}
