.login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.05);
    text-align: center;
    overflow: auto;

    &__logo {
        height: 4rem;
        margin: 2rem 0;
    }

    &__holder {
        background-color: #fff;
        width: 100%;
        max-width: 50rem;
        padding: 4rem;
        @include shadow-small;
        border-radius: 4px;

        .form-group {
            label {
                display: none;
            }
        }
    }
}
