h1,
h2,
h3,
h4 {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 2.5rem;
}

ul {
    li {
        margin-bottom: 0.5rem;
    }

    &.inline {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 0.5rem;
        }
    }
}

.backlink {
    text-decoration: none;
    margin-bottom: 2rem;
    display: block;
    padding: 1rem 0;
}

.sectiontitle {
    font-weight: bold;
    margin: 2rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--first {
        margin-top: 0;
    }

    &--no-margin {
        margin: 0;
    }

    &--no-bottom-margin {
        margin-bottom: 0;
    }

    &--block {
        display: block;
    }

    h3 {
        font-size: 2rem;
    }

    &--required {
        justify-content: initial;

        &:after {
            margin-left: 0.5rem;
            content: ' ';
            background-color: $red;
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 1rem;
        }
    }
}

blockquote {
    border-left: 2px solid #eee;
    padding: 1rem 0 1rem 2rem;
}
