.loading {
    &__page {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                border-radius: 3px;
                height: 4rem;
                width: 4rem;
                padding: 0.5rem;
                margin-bottom: 2rem;
            }
        }
    }
}
