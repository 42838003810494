.dashboard {
    display: flex;
    flex-wrap: wrap;

    &__tile {
        width: calc(50% - 2rem);
        margin-right: 2rem;
        margin-bottom: 2rem;
        background-color: #fff;
        border: 1px solid $border;
        padding: 1.5rem 2rem 2rem 2rem;

        &--2 {
            width: calc(66.66% - 2rem);
        }

        &--large {
            width: 100%;
        }

        &__title {
            font-weight: bold;
            padding: 0;

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
        }

        &__content {
            padding: 1rem 0 0 0;
        }

        &--no-margin {
            margin: 0;
        }
    }
}
