.comments {
    &__item {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $border;

        &__author {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > span {
                display: flex;
                align-items: center;
            }

            small {
                opacity: 0.5;
            }
        }

        &__body {
            padding-top: 1rem;
            p {
                padding: 0 0 1rem 0;
                margin: 0;
            }

            input {
                background-color: #f4f4f4;
            }
        }
    }

    &__form {
    }
}
