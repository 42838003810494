.avatar-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        margin-left: -0.75rem;
        display: inline-block;

        &:first-child {
            margin: 0;
        }

        img {
            border: 2px solid #fff;
            min-width: 3.5rem;
            max-width: 3.5rem;
            min-height: 3.5rem;
            max-height: 3.5rem;
            border-radius: 2rem !important;
        }
    }
}

.avatar {
    border: 2px solid #fff;
    min-width: 1.75rem;
    max-width: 1.75rem;
    min-height: 1.75rem;
    max-height: 1.75rem;
    border-radius: 2rem !important;
    margin-right: 1rem;
}
