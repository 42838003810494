.cards {
    display: flex;
    flex-wrap: wrap;

    &__item {
        min-width: 30rem;
        max-width: 30rem;
        padding: 2rem;
        background-color: #fff;
        border: 1px solid $border;
        margin-bottom: -1px;
        display: flex;
        flex-direction: column;
        margin: 0 2rem 2rem 0;
    }

    &__icon {
        border: 1px solid $border;
        height: 5rem;
        width: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin-bottom: 2rem;

        &--green {
            border: 0;
            background-color: $green;
            color: #fff;
        }
        &--blue {
            border: 0;
            background-color: $blue;
            color: #fff;
        }
        &--purple {
            border: 0;
            background-color: $purple;
            color: #fff;
        }
        &--grey {
            border: 0;
            background-color: $grey;
            color: #fff;
        }
        &--beige {
            border: 0;
            background-color: $beige;
            color: #fff;
        }
        &--yellow {
            border: 0;
            background-color: $yellow;
            color: #fff;
        }

        i {
            font-size: 3rem;
        }
    }

    &__title {
        font-weight: bold;
    }

    &__description {
        color: rgba(#000, 0.5);
        font-size: 1.5rem;
    }

    &__image {
        margin-bottom: 1rem;

        img {
            height: 5rem;
        }
    }
}
