@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://unicons.iconscout.com/release/v3.0.6/css/line.css');
@import url('https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css');

/* Bootstrap required files */
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import './node_modules/bootstrap/scss/mixins';
@import "./node_modules/bootstrap/scss/maps";
@import './node_modules/bootstrap/scss/utilities';
@import './node_modules/angular-ng-autocomplete/assets/styles/utilities';

/* Include what ya need */
@import './node_modules/bootstrap/scss/dropdown';
@import './node_modules/bootstrap/scss/tooltip';
@import './node_modules/bootstrap/scss/popover';
@import './node_modules/bootstrap/scss/modal';
@import './node_modules/bootstrap/scss/grid';

@import './../frontend/node_modules/ngx-toastr/toastr';

/*
@include media-breakpoint-down(xs) { ... }
@include media-breakpoint-down(sm) { ... }
@include media-breakpoint-down(md) { ... }
@include media-breakpoint-down(lg) { ... }
*/

@import 'settings/reset';
@import 'settings/colors';
@import 'settings/typo';
@import 'settings/helpers';

@import 'app';
@import 'avatar';
@import 'badge';
@import 'budget';
@import 'cards';
@import 'form';
@import 'button';
@import 'dropdown';
@import 'filter';
@import 'onboarding';
@import 'pagination';
@import 'modal';
@import 'messages';
@import 'list';
@import 'loading';
@import 'login';
@import 'resume';
@import 'search';
@import 'tabs';
@import 'tags';
@import 'tooltip';
@import 'popover';
@import 'timeline';
@import 'views';
@import 'pills';
@import 'standalone';
@import 'modules';
@import 'dashboard';
@import 'charts';
@import 'kanban';
@import 'comments';
@import 'documents';
@import 'marketing';
@import 'homepage';
@import 'mobiscroll';
@import 'graph';
@import 'table';

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

i.material-icons.close {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
