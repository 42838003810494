.timeline {
    position: relative;

    &:after {
        content: ' ';
        border-left: 1px solid $border;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 1.45rem;
        z-index: 0;
    }

    li {
        position: relative;
        font-size: 1.75rem;
        padding-left: 7rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;

        /*&:after {
            content:' ';
            position: absolute;
            left: 5rem;
            bottom: 0;
            border-top: 1px solid $border;
            height: 1px;
            width: 100%;
        }

        &:last-child {
            &:after { display: none; }
        }*/

        &:last-child {
            padding-bottom: 0;
        }

        small {
            display: block;
            font-size: 1.25rem;
            opacity: 0.5;
        }

        time {
            display: block;
            font-size: 1.25rem;
            opacity: 0.5;
        }

        &:before {
            background: #fff;
            color: $blue;
            border: 1px solid $border;
            width: 4rem;
            height: 4rem;
            text-align: center;
            line-height: 4rem;
            border-radius: 2rem;
            position: absolute;
            left: 0;
            font-size: 2.25rem;
            display: inline-block;
            top: 0;
            content: '\e8e9';
            font-family: 'unicons-line';
            z-index: 1;
        }

        &.send:before {
            content: '\e9bc';
            color: $blue;
        }
        &.feedback:before {
            content: '\e940';
            color: $red;
        }
        &.cv:before {
            content: '\e9dd';
            color: $green;
        }
    }
}
