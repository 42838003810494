@import 'button';
@import 'settings/colors';

.pagination {
    @include button;
    background-color: white;
    border: rgba(0, 0, 0, 0.03) solid 1px;
    cursor: auto;

    &--page {
        padding: 0 1rem;
        min-width: 5rem;
    }
}
