@import "./settings/colors";

@mixin tag {
    display: inline-flex;
}

.tag {
    background-color: $tagcolor;
    color: #333;

    font-size: 1.25rem;
    line-height: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    display: inline-block;

    &--mini {
        @include tag;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 1rem;
    }

    &__badge {
        display: inline-block;
        margin-left: 1rem;
        background-color: rgba(255, 255, 255, 0.4);
        color: rgba(0, 0, 0, 0.3);
        padding: 0.5rem 1rem;
        border-radius: 4px;
        margin-right: -0.5rem;
        min-width: 3rem;
        text-align: center;
    }

    &--big {
        padding: 0.75rem 1.5rem;
        font-size: 1.5rem;
    }

    &--green {
        background-color: $green;
        color: #fff;
    }
    &--red,
    &--danger {
        background-color: $red;
        color: #fff;
    }
    &--orange,
    &--warning {
        background-color: $orange;
        color: #fff;
    }
    &--blue {
        background-color: $blue;
        color: #fff;
    }
    &--purple {
        background-color: $purple;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;

        .button--icon, .pill {
            margin-right: 0;
        }

        .pill {
            margin: 0 .5rem;
        }
    }

    &--hover {
        &:hover {
            background-color: $grey;
        }
    }
}

.selected-tag {
    display: inline-block;
    margin-right: 15px;
    font-size: 10px;
    background: #b9cfea;
    margin-bottom: 10px;
    padding: 2px 11px 4px 11px;
    border-radius: 8px;
}

.filter-input {
    font-size: 15px;
    border-radius: 3px;
    border: 2px solid #ccc;
    margin-bottom: 5px;
    margin-left: 5px;
    padding: 5px 30px 5px 5px;
}

.filter-clear {
    position: absolute;
    margin-left: -24px;
    margin-top: 11px;
    cursor: pointer;
}

.clear-selection {
    font-size: 12px;
    font-weight: bold;
    color: red;
    cursor: pointer;
}
