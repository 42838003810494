@mixin button {
    display: inline-flex;
    height: 5rem;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;

    font-size: 1.6rem;
    font-weight: 500;
    border: 0;
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scale(10, 10);
        opacity: 0;
        transition: transform 0.5s, opacity 1s;
    }

    &:active:before {
        transform: scale(0, 0);
        opacity: 0.5;
        transition: 0s;
    }
}

@mixin button--outline {
    background-color: #fff;
    border: 1px solid $border;
    color: #000;
}

@mixin button--disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.button {
    @include button;

    &:active,
    &:focus {
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.5) inset;
        outline: none;
        opacity: 0.75;
    }

    &--primary {
        background-color: $blue;
        color: #fff;
    }

    &--success {
        background-color: $green;
        color: #fff;
    }

    &--danger {
        background-color: $red;
        color: #fff;
    }

    &--outline {
        @include button--outline;
    }

    &--extra {
        background-color: $purple;
        color: #fff;
    }

    &--light {
        border: 1px solid rgba($blue, 0.1);
        background-color: rgba($blue, 0.05);
        color: $blue;
    }

    &--notifications-unarchive {
        width: fit-content;
        font-size: 1.2rem;
        height: auto;
        padding: 1.2rem;
        background-color: #f2f4f7;
        transition: background-color;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
        }
    }

    &--ghost {
        background-color: transparent;
        border: 1px solid transparent;
    }

    &--rounded {
        border-radius: 3rem;
    }

    &[disabled],
    &--disabled {
        @include button--disabled;
    }

    &--icon {
        padding: 0 1rem;
        min-width: 5rem;

        i {
            font-size: 2rem;

            &:after {
                margin: 0;
            }
        }

        &-before {
            i {
                font-size: 2rem;
                margin-right: 1rem;
            }
        }
    }

    &--small {
        height: 4rem;
        font-size: 1.5rem;
        padding: 0 1.5rem;

        &.button--icon {
            padding: 0 0.5rem;
            min-width: 4rem;
        }
    }

    &--big {
        height: 6rem;
        font-size: 2rem;
        padding: 0 2.5rem;
    }

    &--social-login {
        @include button--outline;

        img {
            height: 2rem;
            margin-right: 1rem;
        }
    }

    &--loading {
        @include button--disabled;

        &:before {
            content: ' ';
            width: 2rem;
            height: 2rem;
            background-image: url('/assets/img/loading-light.svg');
            background-size: cover;
            margin-right: 1rem;
        }
    }

    &--refresh {
        i {
            animation: refresh-icon-spin 2s infinite ease-in-out;
        }
    }
}

@keyframes refresh-icon-spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.radio {
    &-btn {
        display: none;

        &:checked + .radio-label {
            border: 2px solid #006eff;
        }

        &:checked + .radio-label__content {
            border-color: #006eff;
            background-color: #006eff;
            color: #fff
        }
    }

    &-label {
        background: #fff;
        border: 1px solid #ddd;
        margin: 0.5rem;
        border-radius: 0.5rem;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &-label--icon {
        font-size: 2.5rem;
        width: 7rem;
        height: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &-label--text {
        cursor: pointer;
        margin: 0;
        width: 100%;
        border: none;

        .radio-label__content {
            border: 1px solid #ddd;
            border-radius: 0;
            border-right-width: 0;
            padding: 1rem;
        }


        &:first-of-type {
            .radio-label__content {
                border-bottom-left-radius: .5rem;
                border-top-left-radius: .5rem;
            }
        }

        &:last-of-type {
            .radio-label__content {
                border-bottom-right-radius: .5rem;
                border-top-right-radius: .5rem;
                border-right-width: 1px;
            }
        }
    }
}

.flag-icon {
    height: 16px;
    width: 16px;

    &--filled {
        fill: $reddark;
    }
}

.icons-list {
    display: flex;

    .button:not(:last-child) {
        margin-right: 1rem;
    }
}
