.kanban {
    display: flex;
    border-radius: 4px;
    // border: 1px solid rgba(0,0,0,.05);
    overflow-x: auto;
    margin-right: -4rem;
    padding-bottom: 2rem;

    &__lane {
        padding: 0;
        flex: 1;
        min-width: 30rem;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 4px;
        margin-right: 1.5rem;

        &__title {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding: 1rem 2rem;
            background-color: rgba(#fff, 1);

            span {
                display: flex;
                justify-content: space-between;
            }
            strong,
            small {
                display: block;
            }

            small {
                opacity: 0.7;
                font-size: 1.2rem;
            }
        }

        .cdk-drop-list {
            min-height: 10rem;
            padding: 1rem;

            &-dragging {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }

    &__items {
    }

    &__add {
        text-align: center;
        font-weight: 700;
        font-size: 1.25rem;
        text-transform: uppercase;
        opacity: 0.5;
        cursor: pointer;
        padding: 0rem 1rem 2rem 1rem;
    }

    &__item {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 1.5rem;
        font-size: 1.5rem;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.02);
        margin-bottom: 1rem;
        cursor: move;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
