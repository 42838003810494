$border: #eee;
$purple: #570fe7;
$red: #e24600;
$orange: #ec740b;
$blue: #006eff;
$green: #26b305;
$yellow: #ffba00;
$grey: #ccc;
$light-grey: #f8f8f8;
$bluegrey: #3c6382;
$greenblue: #38ada9;
$reddark: #b71540;
$beige: #f8c291;
$gray: #858585;

$linkedin: #2867b2;
$facebook: #4267b2;
$instagram: #e1306c;
$whatsapp: #4ac959;
$youtube: #ff0000;
$tagcolor: #eff3f9;

@mixin shadow-small {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.color {
    &--blue {
        color: $blue;
    }
    &--grey {
        color: $grey;
    }
    &--green {
        color: $green;
    }
    &--red {
        color: $red;
    }
    &--dark-red {
        color: $reddark;
    }
    &--purple {
        color: $purple;
    }
    &--yellow {
        color: $yellow;
    }
    &--muted {
        color: #ccc;
    }
}
