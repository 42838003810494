.graph {
    &__container {
        display: flex;
        flex-flow: column;
        height: 100%;
    }

    &__chart {
        flex: 1;
        display: flex;
        flex-flow: column;
    }

    &__loading {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
