table {
    border-collapse: collapse;
}
tr {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
th, td {
    text-align: left;
    padding: 4px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}