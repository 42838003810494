.homepage {
    header {
        padding: 2rem 0;
    }

    &__hero {
        background-color: #fff;
        padding: 8rem 0;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        margin-bottom: 8rem;

        h1 {
            font-size: 6rem;
            line-height: 6rem;
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 4rem;
        }

        img {
            width: 100%;
            max-width: 50rem;
            display: inline-block;
        }

        &__content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__usp {
        text-align: center;

        p,
        h3 {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        img {
            width: 8rem;
            margin-bottom: 2rem;
        }
    }
}
