.filter {
    &__toggle {
        width: 2rem;
        margin-right: 2rem;

        .uil {
            font-size: 2rem;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 0.75rem 0;
        cursor: pointer;

        &--selected {
            font-weight: bold;
        }
    }

    &__content {
        width: 100%;
    }
}
