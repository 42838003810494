@import "./settings/colors";

@mixin resumeBlockDate {
    color: #777;
}
@mixin resumeBlockTags {
    margin-top: 2rem;
}
@mixin resumeBlockWebsite {
    padding-bottom: 1rem;
}

.resume-v2 {

    &__header {
        display: flex;
        justify-content: space-between;

        &-info {
            display: flex;
            align-items: center;
        }

        &-actions {
            display: flex;
        }
    }

    &__avatar {
        border-radius: .5rem;
        height: 5rem;
        margin-right: 2rem;
    }

    &__title {
        font-size: 2rem;
    }

    &__info {
        font-size: 1.5rem;
        color: $gray;
    }

    &__variant-select {
        background-color: transparent;
        font-size: 1.5rem;
    }

    &__actions {
        height: 5rem;
    }

    &__section {

        &-title {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }

        .table-view__add {
            text-decoration: none;
            color: $gray;
        }
    }

    &__experience {
        padding: 1rem;

        &-container {
            margin-bottom: 2rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;

            .pill {
                margin-right: 0;
            }

            &-container {
                display: flex;
                justify-content: space-between;
            }
        }

        &-job-title {
            font-weight: bold;
            margin-bottom: 1rem;
        }
    }
}

.resume {
    padding: 8rem;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    margin: 0 auto;
    border-radius: 4px;
    max-width: 110rem;

    .modal & {
        padding: 0;
        box-shadow: none;
        border-radius: 0;
        max-width: 100%;
    }

    @mixin resumeBlock {
        background-color: #fff;
        border: 1px solid $border;
        padding: 2rem 2rem 3rem 3rem;
        margin-bottom: -1px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        &__title {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            h3 {
                font-size: 2rem;
            }

            time {
                @include resumeBlockDate;
            }
        }
    }

    &__project,
    &__education,
    &__experience {
        @include resumeBlock;

        &__tags {
            @include resumeBlockTags;
        }

        &__website {
            @include resumeBlockWebsite;
        }

        &__content {
            width: 100%;
            margin-top: 2rem;

            p {
                margin-bottom: 1rem;
            }
        }

        &__images {
            display: flex;
            width: 100%;
            margin-bottom: 2rem;
            margin-top: 2rem;

            span {
                display: flex;
                width: 33%;
                margin-right: 2%;

                &:last-child {
                    margin-right: 0;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 150px;
                    width: 100%;
                }
            }
        }
    }

    &__header {
        display: flex;
        margin-bottom: 7rem;
        border-bottom: 1px solid $border;
        padding-bottom: 7rem;

        strong {
            display: block;
            margin-bottom: 1rem;
            font-weight: 300;
        }

        small {
            display: block;
        }
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    footer {
    }

    &__info {
        width: 100%;
    }

    &__company {
        img {
            max-height: 4rem;
            max-width: 15rem;
            float: left;
        }
    }

    &__actions {
        display: flex;
        align-items: flex-start;
    }

    &__avatar {
        max-width: 10rem;
        margin-right: 4rem;

        img {
            width: 10rem;
            height: 10rem;
            border-radius: 4px;
            float: left;
        }
    }

    &__tags {
        margin-bottom: 2rem;
    }

    &__gallery {
        margin-bottom: 2rem;
        margin-top: 2rem;
        display: flex;
        flex: 1;

        a {
            display: flex;
            flex: 1;
            margin: 0 1rem;

            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
        }

        img {
            width: 100%;
        }
    }

    section {
        margin-bottom: 8rem;

        header {
            font-size: 2.5rem;

            font-weight: 300;
            margin-bottom: 2rem;
        }

        article {
        }
    }
}
