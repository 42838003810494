.standalone {
    &__page {
        display: flex;
        align-items: center;
        padding: 2rem;

        &__content {
            margin: 0 auto;
            width: 100%;
            background-color: #fff;
            width: 100%;
            max-width: 50rem;
            padding: 4rem;
            @include shadow-small;
            border-radius: 4px;

            &--wide {
                max-width: 100rem;
            }
        }

        &__logo {
            display: flex;
            align-items: center;
            margin-bottom: 4rem;

            img {
                height: 4rem;
                margin-right: 2rem;
            }
        }
    }
}
