ng-autocomplete,
.ng-autocomplete {
    width: 100% !important;
}

.autocomplete {
    &-container {
        font-size: 1.6rem;
        border-radius: 4px;
        border: 0;
        box-shadow: none !important;
        height: 5rem;
        padding: 0;
        border-radius: 3px;

        .input-container {
            input {
                border: 1px solid $border !important;
                padding: 1.5rem 1.5rem !important;
                font-size: 1.7rem !important;
            }
            .x i {
                font-size: 2rem !important;
            }
        }

        .not-found {
            text-decoration: none !important;
            padding: 0.75rem 1.5rem !important;
            font-size: 1.6rem !important;
            cursor: pointer;

            div {
                border: 0 !important;
            }
        }

        .suggestions-container {
            font-size: 1.6rem;
            box-shadow: none !important;

            ul {
                border-radius: 0px;
                padding: 0;
                max-height: 16rem;

                li {
                    a {
                        text-decoration: none !important;
                        padding: 0.75rem 1.5rem !important;
                        font-size: 1.6rem !important;
                        cursor: pointer;

                        &:hover {
                            background-color: #f4f4f4;
                            color: inherit;
                        }
                    }
                }
            }
        }

        &.active {
            .suggestions-container ul {
                border: 1px solid $border !important;
                margin-top: -1px;
            }
        }
    }
}
.dropdown {
    &-toggle {
        padding-right: 1rem;

        &:after {
            margin: 0 1rem !important;
            content: '\22EE' !important;
            font-size: 2rem;
            font-weight: bold;
            border: 0 !important;
        }

        &.button--select:after {
            content: '\f107' !important;
            font-family: 'Line Awesome Free';
            font-size: 1.5rem;
        }

        &.button--icon {
            &:after {
                margin: 0 0.5rem;
            }
        }
        span {
            margin-left: 1rem;
        }
    }

    &-header {
        font-size: 1.25rem;
        text-transform: uppercase;
    }

    &-menu {
        font-size: 1.6rem;
        border-radius: 4px;
        border: 1px solid $border;
        padding: 1rem 0;

        &-right {
            left: auto;
            right: 0;
        }
    }

    &-item {
        text-decoration: none;
        padding: 0.75rem 1.5rem;
        cursor: pointer;

        &:hover {
            background-color: #f4f4f4;
            color: inherit;
        }
    }
}
