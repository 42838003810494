.pill {
    text-decoration: none;
    color: #000;

    border-radius: 4px;
    height: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2rem;

    padding: 0;
    font-size: 1.5rem;
    cursor: pointer;
    max-width: 100%;
    position: relative;
    z-index: 2;

    white-space: nowrap;

    & > div {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
    }

    .message--loading {
        margin: 0;
        padding: 0;
        display: flex;
    }

    &:after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        margin: -0.25rem 0 0 -0.5rem;
        border: 1px solid rgba($border, 0.5);
        height: calc(100% + 0.5rem);
        width: calc(100% + 1rem);
        z-index: -1;
        border-radius: 4px;
    }

    &:hover {
        &:after {
            border: 1px solid #ccc;
        }
    }

    i {
        font-size: 1.5rem;
        margin-right: 1rem;
    }

    img {
        height: 1.75rem;
        width: 1.75rem;
        border-radius: 1rem;
        margin-right: 1rem;
    }

    &--email {
        i {
            color: $blue;
        }
    }
    &--telephone {
        i {
            color: $green;
        }
    }
    &--whatsapp {
        i {
            color: $whatsapp;
        }
    }
    &--linkedin {
        i {
            color: $linkedin;
        }
    }
    &--partner {
        i {
            color: $red;
        }
    }
    &--url {
        i {
            color: $purple;
        }
    }
    &--website {
        i {
            color: $bluegrey;
        }
    }
    &--youtube {
        i {
            color: $youtube;
        }
    }
    &--facebook {
        i {
            color: $facebook;
        }
    }
    &--instagram {
        i {
            color: $instagram;
        }
    }
    &--mailchimp {
        i {
            color: $yellow;
        }
    }
    &--deal {
        i {
            color: $yellow;
        }
    }
    &--timesheet-edit {
        i {
            color: $grey;
        }
    }
    &--timesheet-review {
        i {
            color: $red;
        }
    }
    &--timesheet-done {
        i {
            color: $green;
        }
    }
    &--comanageOffer {
        i {
            color: #4b0082;
        }
    }
    &--pdf {
        i {
            color: $red;
        }
    }
    &--word {
        i {
            color: $blue;
        }
    }
    &--xlsx {
        i {
            color: $green;
        }
    }
    &--jpeg {
        i {
            color: $orange;
        }
    }

    &--error {
        color: $red;
        i {
            color: $red;
        }
    }

    &--address {
        i {
            color: $red;
        }

        span {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
