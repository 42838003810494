@import 'settings/colors';

$mbsc-windows-calendar-text: #000;
$mbsc-windows-calendar-accent: #001f48;
$mbsc-windows-calendar-border: #eee;
$mbsc-windows-calendar-mark: red;

/* Mobiscroll */
@import '~@mobiscroll/angular/dist/css/mobiscroll.scss';
.mbsc-windows {
    &.mbsc-calendar-width-md {
        .mbsc-calendar-title {
            font-size: 1.5rem;
            font-weight: 700;
        }
    }

    a {
        color: #000;
        font-weight: normal;
    }
}

.mbsc-timeline-header-date-text,
.mbsc-button,
.mbsc-calendar-year-text,
.mbsc-calendar-month-text {
    font-size: 1.5rem;
}
