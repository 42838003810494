.search {
    width: 100%;
    position: relative;

    &:before {
        content: '\e99a';
        font-family: 'unicons-line';
        position: absolute;
        font-size: 2rem;
        color: #ccc;
        height: 5rem;
        width: 5rem;
        margin: 1px;
        text-align: center;
        line-height: 5rem;
        pointer-events: none;
    }

    input {
        width: 100%;
        padding-left: 5rem;

        background-color: rgba(0, 0, 0, 0);
    }
}
