.nav {
    border-bottom: 1px solid $border;
    display: flex;
    margin-bottom: 2rem;

    &-item {
        color: #777;
        padding: 1rem 1.5rem;
        font-size: 1.6rem;
        cursor: pointer;
        text-decoration: none;

        &.active {
            color: #000;
            position: relative;

            &:before {
                content: ' ';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-bottom: 1px solid $blue;
                margin-bottom: -1px;
            }
        }
    }
}

.tabs__modal {
    padding: 0 1.5rem;
    height: 45px;
    margin: 0.5rem 0 0;
}
