@mixin listitem {
    background-color: #fff;
    border: 1px solid $border;
    padding: 2rem;
    margin-bottom: -1px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

@mixin icon {
    margin-right: 3rem;
    min-width: 5rem;
    min-height: 5rem;
    max-width: 5rem;
    max-height: 5rem;
    border-radius: 2.5rem;
    text-align: center;
    line-height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 3rem;
    }

    img {
        height: 3rem;
        width: 3rem;
        border-radius: 1.5rem;
    }

    &.icon--red {
        color: $red;
        background-color: rgba($red, 0.05);
    }
    &.icon--blue {
        color: $blue;
        background-color: rgba($blue, 0.05);
    }
    &.icon--yellow {
        color: $orange;
        background-color: rgba($orange, 0.05);
    }
    &.icon--grey {
        color: $grey;
        background-color: rgba($grey, 0.05);
    }
}

@mixin iconsmall {
    @include icon;
    margin-right: 2rem;
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    line-height: 4rem;

    i {
        font-size: 2.5rem;
    }
}

.list {
    &__dashboard {
        height: 100%;
        overflow-y: auto;

        li {
            display: flex;
            flex-direction: column;
            line-height: 2.5rem;
            border-bottom: 1px dashed #eee;
            padding-bottom: 1rem;
            margin-bottom: 1rem;

            small {
                opacity: 0.5;
                font-size: 1.25rem;
            }
        }
    }

    &__specs {
        li {
            display: flex;
            margin-bottom: 1rem;
            align-items: center;

            label {
                display: inline-block;
                min-width: 12rem;
                max-width: 12rem;
                font-size: 1.5rem;
                line-height: 4rem;
            }

            .pill {
                margin-left: 0;
            }
        }
    }

    &__stack {
        border-bottom: 1px solid $border;
        padding: 1rem 0;
    }

    &__item {
        @include listitem;
        text-decoration: none;
        color: #000;

        small {
            font-weight: 300;
            color: #777;
        }

        &__select {
            text-align: center;
            margin-right: 2rem;
            min-width: 4rem;
            min-height: 4rem;
            max-width: 4rem;
            max-height: 4rem;
            line-height: 4rem;
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-weight: 500;

            strong {
                font-weight: 500;
            }
        }

        &__extra {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            > span {
                margin-left: 2rem;
                white-space: nowrap;
            }
        }

        &__icon {
            @include iconsmall;
        }

        &--company-invite {
            .list__item {
                &__icon {
                    background: lighten($blue, 50%);
                    @include icon;
                    i {
                        color: $blue;
                    }
                }
            }
        }

        &--resume {
            cursor: pointer;

            .list__item {
                &__icon {
                    background: lighten($purple, 50%);
                    @include icon;
                    min-width: 6rem;
                    max-width: 6rem;
                    min-height: 6rem;
                    max-height: 6rem;
                    border-radius: 3.5rem;
                    margin-right: 2rem;

                    img {
                        height: 4rem;
                        width: 4rem;
                        border-radius: 2.5rem;
                    }
                    i {
                        color: $purple;
                    }
                }
            }
        }

        &--skill {
            padding: 1.5rem 2rem;
        }

        &--user {
            @include listitem;

            img {
                width: 4rem;
                border-radius: 2rem;
                margin-right: 2rem;
                float: left;
            }
        }

        &--small {
            font-size: 1.5rem;
            margin: 0;
            border: 0;
            border-bottom: 1px solid $border;
            padding: 1rem 0;
        }

        &.selected {
            strong {
                color: $blue;
                font-weight: 600;
            }
        }
    }

    &__resumes__sidebar {
        .list__item {
            border-right: 0;
            border-left: 0;

            img {
                width: 4rem;
                border-radius: 2rem;
            }
        }
    }
}
