$dirs: 'top' 't', 'bottom' 'b', 'left' 'l', 'right' 'r';
$sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

.nowrap {
    white-space: nowrap;
}

.pos {
    &-relative {
        position: relative;
    }
}

.d {
    &-flex {
        display: flex;
    }

    &-block {
        display: block;
    }
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.content-center {
    justify-content: center;
}

.background-cover {
    background-size: cover;
}

.background-center {
    background-position: center;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.float-right {
    float: right;
}

.m-0 {
    margin: 0rem;
}

.m-1 {
    margin: 0.5rem;
}

.m-2 {
    margin: 1rem;
}

.m-3 {
    margin: 1.5rem;
}

.m-4 {
    margin: 2rem;
}

.m-5 {
    margin: 2.5rem;
}

.m-6 {
    margin: 3rem;
}

.m-7 {
    margin: 3.5rem;
}

.m-8 {
    margin: 4rem;
}

.m-9 {
    margin: 4.5rem;
}

.m-10 {
    margin: 5rem;
}

.m-t-0 {
    margin-top: 0rem;
}

.m-t-1 {
    margin-top: 0.5rem;
}

.m-t-2 {
    margin-top: 1rem;
}

.m-t-3 {
    margin-top: 1.5rem;
}

.m-t-4 {
    margin-top: 2rem;
}

.m-t-5 {
    margin-top: 2.5rem;
}

.m-t-6 {
    margin-top: 3rem;
}

.m-t-7 {
    margin-top: 3.5rem;
}

.m-t-8 {
    margin-top: 4rem;
}

.m-t-9 {
    margin-top: 4.5rem;
}

.m-t-10 {
    margin-top: 5rem;
}

.m-b-0 {
    margin-bottom: 0rem;
}

.m-b-1 {
    margin-bottom: 0.5rem;
}

.m-b-2 {
    margin-bottom: 1rem;
}

.m-b-3 {
    margin-bottom: 1.5rem;
}

.m-b-4 {
    margin-bottom: 2rem;
}

.m-b-5 {
    margin-bottom: 2.5rem;
}

.m-b-6 {
    margin-bottom: 3rem;
}

.m-b-7 {
    margin-bottom: 3.5rem;
}

.m-b-8 {
    margin-bottom: 4rem;
}

.m-b-9 {
    margin-bottom: 4.5rem;
}

.m-b-10 {
    margin-bottom: 5rem;
}

.m-l-0 {
    margin-left: 0rem;
}

.m-l-1 {
    margin-left: 0.5rem;
}

.m-l-2 {
    margin-left: 1rem;
}

.m-l-3 {
    margin-left: 1.5rem;
}

.m-l-4 {
    margin-left: 2rem;
}

.m-l-5 {
    margin-left: 2.5rem;
}

.m-l-6 {
    margin-left: 3rem;
}

.m-l-7 {
    margin-left: 3.5rem;
}

.m-l-8 {
    margin-left: 4rem;
}

.m-l-9 {
    margin-left: 4.5rem;
}

.m-l-10 {
    margin-left: 5rem;
}

.m-r-0 {
    margin-right: 0rem;
}

.m-r-1 {
    margin-right: 0.5rem;
}

.m-r-2 {
    margin-right: 1rem;
}

.m-r-3 {
    margin-right: 1.5rem;
}

.m-r-4 {
    margin-right: 2rem;
}

.m-r-5 {
    margin-right: 2.5rem;
}

.m-r-6 {
    margin-right: 3rem;
}

.m-r-7 {
    margin-right: 3.5rem;
}

.m-r-8 {
    margin-right: 4rem;
}

.m-r-9 {
    margin-right: 4.5rem;
}

.m-r-10 {
    margin-right: 5rem;
}

.-m-0 {
    margin: 0rem;
}

.-m-1 {
    margin: 0.5rem;
}

.-m-2 {
    margin: 1rem;
}

.-m-3 {
    margin: 1.5rem;
}

.-m-4 {
    margin: 2rem;
}

.-m-5 {
    margin: 2.5rem;
}

.-m-6 {
    margin: 3rem;
}

.-m-7 {
    margin: 3.5rem;
}

.-m-8 {
    margin: 4rem;
}

.-m-9 {
    margin: 4.5rem;
}

.-m-10 {
    margin: 5rem;
}

.-m-t-0 {
    margin-top: 0rem;
}

.-m-t-1 {
    margin-top: 0.5rem;
}

.-m-t-2 {
    margin-top: 1rem;
}

.-m-t-3 {
    margin-top: 1.5rem;
}

.-m-t-4 {
    margin-top: 2rem;
}

.-m-t-5 {
    margin-top: 2.5rem;
}

.-m-t-6 {
    margin-top: 3rem;
}

.-m-t-7 {
    margin-top: 3.5rem;
}

.-m-t-8 {
    margin-top: 4rem;
}

.-m-t-9 {
    margin-top: 4.5rem;
}

.-m-t-10 {
    margin-top: 5rem;
}

.-m-b-0 {
    margin-bottom: 0rem;
}

.-m-b-1 {
    margin-bottom: 0.5rem;
}

.-m-b-2 {
    margin-bottom: 1rem;
}

.-m-b-3 {
    margin-bottom: 1.5rem;
}

.-m-b-4 {
    margin-bottom: 2rem;
}

.-m-b-5 {
    margin-bottom: 2.5rem;
}

.-m-b-6 {
    margin-bottom: 3rem;
}

.-m-b-7 {
    margin-bottom: 3.5rem;
}

.-m-b-8 {
    margin-bottom: 4rem;
}

.-m-b-9 {
    margin-bottom: 4.5rem;
}

.-m-b-10 {
    margin-bottom: 5rem;
}

.-m-l-0 {
    margin-left: 0rem;
}

.-m-l-1 {
    margin-left: 0.5rem;
}

.-m-l-2 {
    margin-left: 1rem;
}

.-m-l-3 {
    margin-left: 1.5rem;
}

.-m-l-4 {
    margin-left: 2rem;
}

.-m-l-5 {
    margin-left: 2.5rem;
}

.-m-l-6 {
    margin-left: 3rem;
}

.-m-l-7 {
    margin-left: 3.5rem;
}

.-m-l-8 {
    margin-left: 4rem;
}

.-m-l-9 {
    margin-left: 4.5rem;
}

.-m-l-10 {
    margin-left: 5rem;
}

.-m-r-0 {
    margin-right: 0rem;
}

.-m-r-1 {
    margin-right: 0.5rem;
}

.-m-r-2 {
    margin-right: 1rem;
}

.-m-r-3 {
    margin-right: 1.5rem;
}

.-m-r-4 {
    margin-right: 2rem;
}

.-m-r-5 {
    margin-right: 2.5rem;
}

.-m-r-6 {
    margin-right: 3rem;
}

.-m-r-7 {
    margin-right: 3.5rem;
}

.-m-r-8 {
    margin-right: 4rem;
}

.-m-r-9 {
    margin-right: 4.5rem;
}

.-m-r-10 {
    margin-right: 5rem;
}

.divider {
    margin: 2rem 0;
    width: 100%;
    border-top: 1px solid $border;
    height: 1px;
    display: block;
}

.spacer {
    height: 2rem;
    width: 100%;
}

.w {
    &-auto {
        width: auto !important;
    }

    &-30 {
        width: 30%;
    }

    &-50 {
        width: 50%;
    }

    &-100 {
        width: 100%;
    }
}

.h {
    &-auto {
        height: auto !important;
    }

    &-fit {
        height: fit-content;
    }

    &-30 {
        height: 30%;
    }

    &-50 {
        height: 50%;
    }

    &-100 {
        height: 100%;
    }
}

.pointer {
    cursor: pointer;
}

.hide {
    @include media-breakpoint-down(sm) {
        &-sm {
            display: none;
        }
    }
}

.no-padding {
    padding: 0 !important;
}

.overflow {
    &--hidden {
        overflow: hidden !important;
    }

    &--visible {
        overflow: visible !important;
    }

    &-y {
        &-scroll {
            overflow-y: scroll !important;
        }
    }

    &-x {
        &-scroll {
            overflow-x: scroll !important;
        }
    }
}

.text-align {
    &--right {
        text-align: right;
    }
}
