.popover {
    border: 1px solid $border;
    padding: 1.5rem;
    max-width: 100%;

    &-header {
        width: 100%;
        font-size: 1.5rem;
        background-color: #fff;
        margin-bottom: 1.5rem;
        padding: 0 0 1rem;
        font-weight: 500;
    }
    &-body {
        padding: 0;
        font-size: 1.5rem;
        line-height: 3rem;
    }
}
