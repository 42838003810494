@mixin statusmessage {
    background-color: #fff;
    border: 1px solid $border;
    padding: 2rem 2rem 2rem 3rem;
    text-align: left;
    border-radius: 3px;
    position: relative;
    font-size: 1.5rem;
    margin-bottom: 2rem;

    &:before {
        content: ' ';
        width: 2px;
        height: calc(100% - 0.5rem);
        position: absolute;
        background-color: $red;
        left: 0.25rem;
        top: 0.25rem;
    }
}

app-message-empty {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeMessage {
    to {
        opacity: 0;
    }
}

.message {
    /* Modules */

    &--module-inactive {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: inherit;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid $border;
        padding: 2rem;
        text-align: left;
        border-radius: 3px;
        position: relative;
        font-size: 1.5rem;
        margin-bottom: 2rem;

        &:after {
            content: ' ';
            width: 5rem;
            height: 5rem;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -2.5rem;
            background-size: 100% auto;
            background-position: center;
            margin-right: 2rem;
            border-radius: 3px;
            background-repeat: no-repeat;
        }

        &--outlook {
            &:after {
                background-image: url(../img/logo-outlook.png);
            }
        }
        &--gmail {
            &:after {
                background-image: url(../img/logo-google.png);
            }
        }
        &--mailchimp {
            &:after {
                background-image: url(../img/logo-mailchimp.png);
            }
        }
        &--teamleader {
            &:after {
                background-image: url(../img/teamleader.png);
            }
        }
        &--comanage {
            &:after {
                background-image: url(../img/comanage.jpg);
            }
        }
        &--exact {
            &:after {
                background-image: url(../img/logo-exact.png);
            }
        }
    }

    /* Statusmessages */

    &--error {
        @include statusmessage;
    }

    &--warning {
        @include statusmessage;
        &:before {
            background-color: $yellow;
        }
    }

    &--urgent {
        @include statusmessage;
        &:before {
            background-color: $red;
        }
    }

    &--success {
        @include statusmessage;
        &:before {
            background-color: $green;
        }
    }

    &--info {
        @include statusmessage;
        &:before {
            background-color: $blue;
        }
    }

    &--loading {
        text-align: center;
        padding: 2rem 0;

        img {
            height: 2rem;
            width: 2rem;
            opacity: 0.5;
        }
    }

    &--floating {
        position: fixed;
        right: 0;
        bottom: 0;
        max-width: 300px;
        margin: 0 2rem 2rem;
        @include shadow-small;
        animation: fadeMessage 1s ease-in 5s forwards;
    }

    /* Other messages */

    &__company-invite {
        background-color: #fff;
        max-width: 800px;
        width: 100%;
        margin: 2rem auto;
        display: flex;
        align-items: center;
        @include shadow-small;

        &__image {
            width: 50%;
            padding: 4rem;
        }

        &__content {
            width: 50%;
            padding: 2rem;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            text-align: center;
            width: 90%;
            max-width: 500px;

            &__image {
                width: 50%;
                padding: 4rem 2rem 2rem 2rem;
            }
            &__content {
                width: 100%;
                padding: 0rem 2rem 8rem 2rem;
            }
        }
    }

    &__empty {
        text-align: center;
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        &__icon {
            margin-bottom: 1rem;

            img {
                width: 6rem;
                height: 6rem;
            }

            i {
                position: relative;
                width: 8rem;
                height: 8rem;
                display: inline-block;

                &:after {
                    content: ' ';
                    left: 0;
                    top: 0;
                    width: 10rem;
                    height: 10rem;
                    position: absolute;
                    background-color: #ccc;
                    z-index: -1;
                    border-radius: 5rem;
                    margin: -1rem 0 0 -1rem;
                    opacity: 0.1;
                }

                &:before {
                    font-size: 6rem;
                    line-height: 8rem;
                    margin: 0;
                    padding: 0;
                }
            }

            &--green {
                i {
                    color: $green;
                    &:after {
                        background-color: $green;
                    }
                }
            }
            &--red {
                i {
                    color: $red;
                    &:after {
                        background-color: $red;
                    }
                }
            }
        }

        &__title {
            font-size: 2rem;
            font-weight: normal;
        }

        p {
            margin-bottom: 4rem;
        }
    }
}

/*
Forbidden, 404, ...
*/

.page {
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;

    h3 {
        font-size: 6rem;
    }

    p {
        margin-bottom: 2rem;
    }
}
