* {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    font-size: 8px;
    min-height: 100%;
}

@include media-breakpoint-down(md) {
    html {
        font-size: 7.5px;
    }
}

@include media-breakpoint-down(sm) {
    html {
        font-size: 7px;
    }
}

body {
    font-size: 1.7rem;
    line-height: 2;
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
    background-color: rgba(0, 110, 255, 0.02);
}

.container-fluid {
    max-width: 1400px;
    padding: 0;
}

::selection {
    color: #fff;
    background: $blue;
}

a {
    color: $blue;
}

.isDisabled {
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none;
}

.resize-vertical {
    resize: vertical;
    max-height: 500px;
}

.format-newlines {
    white-space: pre-wrap;
}

.spacer {
    height: 4rem;
}

.divider {
    border-top: 1px solid $border;
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 100%;

    &--small {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &--nav {
        margin: 1rem;
        width: auto;
        border-color: rgba(238, 238, 238, 0.25);
    }
}

#map {
    width: 100%;
    height: 100%;
}

.app {
    display: flex;

    position: relative;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    &__header {
        border-bottom: 1px solid $border;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        padding: 0 4rem;
        background-color: #fff;

        &__holder {
            min-height: 8rem;
            max-height: 8rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    app-navbar {
        min-width: 9rem;
        max-width: 9rem;
        height: 100vh;
        border-right: 1px solid $border;

        background-color: #001f48;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;

        &:hover {
            min-width: 25rem;
            max-width: 25rem;
            box-shadow: 2px 0 40px rgba(0, 34, 80, 0.1);
            z-index: 10;

            nav {
                ul {
                    li {
                        a {
                            span {
                                display: inline-flex;
                            }
                        }
                    }
                }
            }
        }

        nav {
            height: 100%;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 12px; /* width of the entire scrollbar */
            }

            &::-webkit-scrollbar-track {
                background: #001f48; /* color of the tracking area */
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(255, 255, 255, 0.25);
                border-radius: 20px;
                border: 3px solid #001f48;
            }
        }

        .app__navigation {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: space-between;

            &__logo {
                padding: 2.5rem 0 1.5rem 0;

                margin-left: 2.5rem;

                img {
                    height: 4rem;
                }
            }

            &__avatar {
                text-align: center;
                cursor: pointer;
                padding: 0 2rem;
                border-top: 1px solid #ddd;

                .dropdown-toggle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2rem 0;
                }

                img {
                    width: 4rem;
                    height: 4rem;
                    border-radius: 2rem;
                }
            }
        }

        nav {
            ul {
                overflow: auto;

                li {
                    position: relative;
                    margin-bottom: 0;
                    color: #fff;

                    &.active {
                        background-color: rgba($blue, 0.25);

                        a {
                            font-weight: bold;
                            color: #fff;

                            i {
                                color: #fff;
                            }
                        }

                        /*&:before {
                            content: ' ';
                            width: .5rem;
                            height: 100%;
                            position: absolute;
                            background-color: $blue;

                            @include media-breakpoint-down(md) {
                                width: 100%;
                                height: .25rem;
                            }
                        }*/
                    }

                    a {
                        width: 100%;
                        height: 6rem;

                        display: flex;
                        text-decoration: none;

                        color: rgba(#fff, 0.75);
                        align-items: center;

                        font-size: 1.65rem;

                        span {
                            display: none;
                        }

                        i {
                            font-size: 2.6rem;
                            line-height: 3rem;
                            margin: 0 0 0 1rem;
                            color: rgba(#fff, 0.5);
                            width: 7rem;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &:hover {
                            background-color: $blue;
                            color: #fff;
                            opacity: 1;

                            i {
                                color: #fff;
                            }

                            p {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }

        &__ad {
            padding: 4rem;
        }
    }

    &__logo {
        align-items: center;
        display: flex;

        img {
            height: 3rem;
            margin-right: 1rem;
        }
    }

    &__nav {
        ul {
            display: flex;
            margin-left: 2rem;

            li {
                padding: 0 2rem;

                a {
                    color: #000;
                    text-decoration: none;

                    i {
                        font-size: 2rem;
                        color: #ccc;
                    }
                }
            }
        }
    }

    &__holder {
        height: calc(100vh - 8rem);

        width: 100%;

        display: flex;
        justify-content: center;
        margin: 0 auto;

        padding-left: 9rem;
    }

    &__subnav {
        display: flex;
        flex-direction: column;

        &__content {
            display: flex;
            flex-direction: column;

            small {
                opacity: 0.5;
            }
        }

        &__item,
        > a {
            cursor: pointer;
            display: flex;
            width: 100%;
            padding: 1.25rem 3rem;
            color: #000;
            font-size: 1.625rem;
            text-decoration: none;
            align-items: center;
            justify-content: space-between;

            i {
                font-size: 2rem;
                margin-right: 1rem;
            }

            &:hover {
                background-color: rgba($border, 0.5);

                /*i {
                    color: $blue;
                }*/
            }
        }

        &__child {
            //padding-left: 2rem;
            opacity: 0.75;

            a {
                padding-left: 6rem;
            }
        }

        &__active {
            border-radius: 3px;
            font-weight: bold;

            /*i {
                color: $blue !important;
            }*/
        }

        &__divider {
            border-top: 1px solid $border;
            width: 100%;
            margin: 1rem 0;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5rem;
        margin-bottom: 3rem;
        white-space: nowrap;
    }

    @mixin sidebar {
        border-right: 1px solid $border;
        width: 100%;
        max-width: 32rem;
        min-width: 32rem;
        overflow-y: auto;
        min-height: 100vh;
        background: #fff;
    }

    .sidebar-spacing {
        padding: 2rem 3rem 0 3rem;
    }

    app-sidebar {
        @include sidebar;

        &-right {
            @include sidebar;
            border-left: 1px solid $border;
            border-right: 0;
        }

        header {
            height: 5rem;
            display: flex;
            align-items: center;
            margin-bottom: 2rem;
            justify-content: space-between;
        }
    }

    app-content {
        height: 100vh;
        width: 100%;

        padding: 3rem 4rem;
        overflow: auto;

        @include media-breakpoint-down(md) {
            padding: 2rem;
        }
    }
}

.box {
    background-color: #fff;
    border: 1px solid $border;
    padding: 2rem;
}

.happiness-emoji {
    font-size: 2rem;

    &--gray {
        filter: grayscale(1);
    }
}

.notification {
    i {
        position: relative;
    }

    &__number {
        position: absolute;
        top: -5px;
        right: 5px;
        background-color: #ccc;
        font-size: 1.2rem;
        color: black;
        border-radius: 2rem;
        height: 2rem;
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        background-color: initial !important;
    }
}

iframe {
    border: none;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */
details > summary::-webkit-details-marker /* Safari */ {
    display: none;
}

details {
    position: relative;
    cursor: pointer;
}

.summary {
    &__up-arrow,
    &__down-arrow {
        position: absolute;
        background-color: #fff;
        top: 1rem;
        pointer-events: none;
    }

    &__title {
        padding-left: 3rem;
    }
}
